<template>
  <div
    id="scan"
    class="bg-iq-grey-dark landing-page-section !pt-0 lg:!min-h-[100dvh] lg:!pb-4 !px-8 !pb-12"
  >
    <a id="section-scan" class="absolute top-0 -mt-38" />
    <ClientOnly>
      <TheLandingPageScanResultDialog
        :is-open="isShownScanExample"
        @close:dialog="isShownScanExample = false"
    /></ClientOnly>
    <div class="w-full lg:min-h-[100dvh]">
      <div class="items-center justify-center hidden lg:flex mt-40">
        <NuxtImg
          src="/images/b2bscan-horizontal.svg"
          width="600px"
          height="520px"
          loading="lazy"
          :title="t('logoTitle')"
          :alt="t('logoAlt')"
        />
      </div>

      <div class="lg:px-32">
        <cite
          class="flex flex-col gap-3 px-0 lg:px-8 mt-15 w-full not-italic lg:mt-12 lg:text-regular-32 text-regular-20 mb-4 !text-iq-white text-center"
        >
          <span>{{ t('heading.title') }}</span
          ><br />
          <span>{{ t('heading.subTitle') }}</span>
        </cite>
        <cite
          class="inline-block px-0 lg:px-8 w-full not-italic lg:text-regular-24 text-regular-16 mb-8 text-center !text-iq-white/70"
        >
          {{ t('heading.text') }}
        </cite>
      </div>

      <div class="w-full flex place-content-center">
        <div class="w-full lg:w-[50%]">
          <UiButton
            class="iq-button-transparent mt-8 mb-8"
            @click="isShownScanExample = true"
            >{{ t('buttons.scan_example') }}</UiButton
          >
          <span
            class="text-regular-20 w-full text-center flex justify-center"
            >{{ t('enter_own_scan') }}</span
          >
          <ClientOnly>
            <AppScanLandingPage
              class="w-full mt-8"
              @scan:start="emit('scan:start')"
              @scan:profile:finish="onResult"
              @scan:compatibility="emit('scan:compatibility')"
              @scan:salestext="emit('scan:salestext')"
              @scan:complete="emit('scan:complete')"
              @scan:letter="emit('scan:letter')"
              @scan:letter:finish="emit('scan:letter:finish')"
              @scan:error="emit('scan:error')"
              @scan:has-scanned-user="emit('scan:hasScannedUser')"
            />
          </ClientOnly>
        </div>
      </div>

      <div
        class="pt-8 pb-4 lg:pb-40"
        :class="{
          'hidden results-wrapper': !animate,
          'shown results-wrapper': animate,
        }"
      >
        <div
          v-highlight-index="6"
          class="pt-0 text-center lg:pt-8 text-bold-54 lg:text-bold-72"
        >
          {{ t('scanInstructions.result') }}
        </div>

        <div class="flex flex-col gap-4 lg:pt-12 lg:gap-20 lg:flex-row">
          <TheCollapsible
            v-if="activeScan.companyProfile?.description"
            content-height="50px"
            :title="activeScan.companyProfile?.name"
            :init-open="true"
            :text="activeScan.companyProfile?.description"
            class="mt-8 w-full lg:w-[50%]"
          />
          <TheCollapsible
            v-if="activeScan.targetProfile?.description"
            content-height="50px"
            :title="activeScan.targetProfile?.name"
            :init-open="true"
            :text="activeScan.targetProfile?.description"
            class="mt-8 w-full lg:w-[50%]"
          />
        </div>

        <TheCollapsible
          v-if="activeScan.resultTexts.compatibilityReason"
          :title="t('scanInstructions.compatibility')"
          :init-open="true"
          :text="activeScan.resultTexts.compatibilityReason as string"
          class="w-full mt-8"
        />
        <TheCollapsible
          v-if="activeScan.resultTexts?.whatWhy"
          :title="t('scanInstructions.salesArgumentation')"
          :show-copy="false"
          :text="activeScan.resultTexts?.whatWhy as string"
          :init-open="true"
          class="w-full mt-8"
        />
        <TheCollapsible
          v-if="activeScan.resultTexts?.salesText"
          :title="t('scanInstructions.salesText')"
          :show-copy="false"
          :text="activeScan.resultTexts.salesText as string"
          :init-open="true"
          class="w-full mt-8"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
const { t } = useI18n({ useScope: 'local' });
const { activeScan } = storeToRefs(useScanStore());

const emit = defineEmits<{
  'scan:start': [value?: string];
  'scan:compatibility': [value?: string];
  'scan:salestext': [value?: string];
  'scan:complete': [value?: string];
  'scan:letter': [value?: string];
  'scan:letter:finish': [value?: string];
  'scan:error': [];
  'scan:hasScannedUser': [];
}>();

const animate = ref(false);
const counter = ref(0);
const isShownScanExample = ref(false);
const hasResult = ref(false);

const onResult = () => {
  counter.value += 1;
  if (counter.value < 2) return;
  hasResult.value = true;
  animate.value = true;
  setTimeout(() => {
    window.scrollTo({
      top: window.scrollY + window.innerHeight - 180,
      behavior: 'smooth',
    });
  }, 240);
};
</script>
<i18n lang="json">
{
  "en": {
    "bulletpoints": [
      "Target customer company profile",
      "Compatibility analysis",
      "Benefit argumentation for collaboration",
      "Customized letter",
      "Suggestions for LinkedIn connection requests"
    ],
    "buttons": {
      "scan_example": "SHOW SCAN EXAMPLE"
    },
    "callToAction": "TRY FOR FREE NOW!",
    "enter_own_scan": "or enter your own scan",
    "heading": {
      "title": "Customer acquisition redefined:",
      "subTitle": "Analysing target customers and writing letters 100x faster - for your B2B success",
      "text": "With just one click, our system delivers valuable insights into your B2B target customer, convincing arguments and a personalised cover letter - ready in seconds to inspire your customers!"
    },
    "logoAlt": "B2BScan Logo",
    "logoTitle": "B2BScan Logo",
    "scanInstructions": {
      "compatibility": "Compatibility",
      "result": "RESULTS",
      "salesArgumentation": "Sales argumentation",
      "salesText": "Letter",
      "text": "Simply enter websites and click 'SCAN'",
      "title": "Scan your target customer"
    },
    "subheading": "YOU WILL RECEIVE:"
  },
  "de": {
    "bulletpoints": [
      "Unternehmensprofil Zielkunde",
      "Analyse der Kompatibilität",
      "Nutzenargumentation zur Zusammenarbeit",
      "Kundenspezifisches Anschreiben",
      "Vorschläge zur LinkedIn Vernetzungsanfrage"
    ],
    "buttons": {
      "scan_example": "BEISPIEL SCAN ANSEHEN"
    },
    "callToAction": "JETZT KOSTENLOS TESTEN!",
    "enter_own_scan": "oder eigenen Scan eingeben",
    "heading": {
      "title": "Kundenakquise neu definiert:",
      "subTitle": "Zielkundenanalyse und Anschreiben 100x schneller - für Ihren B2B-Erfolg",
      "text": "Mit einem Klick liefert unser System wertvolle Insights zu deinem B2B-Zielkunden, überzeugende Argumente und ein personalisiertes Anschreiben – in Sekunden bereit, um Deine Kunden zu begeistern!"
    },
    "logoAlt": "B2BScan Logo",
    "logoTitle": "B2BScan Logo",
    "scanInstructions": {
      "compatibility": "Kompatibilität",
      "result": "ERGEBNIS",
      "salesArgumentation": "Verkaufsargumentation",
      "salesText": "Anschreiben",
      "text": "Einfach Webseiten eingeben und auf 'SCAN' klicken",
      "title": "Scanne deinen Zielkunden"
    },
    "subheading": "DU ERHÄLTST:"
  }
}
</i18n>
