<template>
  <TheTabsContent
    :title="header"
    :image="imgTabsScan"
    img-height="300"
    img-width="300"
    :text="text"
    :img-alt="imgAlt"
    :img-title="imgTitle"
  />
</template>
<script lang="ts" setup>
import imgTabsScan from '/images/tabs-scanprofil.svg';
const { t } = useI18n({
  useScope: 'local',
});
const text = computed(() => t('profileText'));
const imgAlt = computed(() => t('imgAlt'));
const imgTitle = computed(() => t('imgTitle'));
const header = computed(() => t('scanProfile'));
</script>

<i18n lang="json">
{
  "en": {
    "profileText": "You can analyze your business potential with target customers both generally and for specific products. Simply add your company link, product landing page, and/or documents such as product descriptions to scan profiles.",
    "imgAlt": "An illustration depicting the convergence of the company profile and products reaching the desired target customer.",
    "imgTitle": "Convergence of Scan Profiles",
    "scanProfile": "SCANPROFILE"
  },
  "de": {
    "profileText": "Du kannst deine Geschäftspotenziale bei den Zielkunden sowohl allgemein, als auch für konkrete Produkte analysieren. Hinterlege dazu einfach deinen Unternehmenslink, deine Produkt-Landingpage und/oder Dokumente, wie Produktbeschreibungen, in Scanprofilen.",
    "imgAlt": "Eine Abbildung, dass den Zusammenfluss von Unternehmensprofil und Unternehmensprodukten darstellt, welche den gewünschten Zielkunden erreichen.",
    "imgTitle": "Zusammenfluss Scanprofile",
    "scanProfile": "SCANPROFIL"
  }
}
</i18n>
