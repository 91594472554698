<template>
  <TheTabsContent
    :title="header"
    :image="imgTabsScan"
    img-height="200"
    img-width="200"
    :text="text"
    :img-alt="imgAlt"
    :img-title="imgTitle"
  />
</template>
<script lang="ts" setup>
import imgTabsScan from '/images/tabs-scan.webp';
const { t } = useI18n({
  useScope: 'local',
});
const text = computed(() => t('scanText'));
const imgAlt = computed(() => t('imgAlt'));
const imgTitle = computed(() => t('imgTitle'));
const header = computed(() => t('theScan'));
</script>

<i18n lang="json">
{
  "en": {
    "scanText": "With a single click, you create a unique customer profile from each target customer's website. This profile is rich in compelling sales arguments and includes a tailored cover letter perfectly suited to the customer's needs.",
    "imgAlt": "A screenshot of the application's scan page",
    "imgTitle": "Scan Page",
    "theScan": "THE SCAN"
  },
  "de": {
    "scanText": "Mit einem einzigen Klick erstellst du aus jeder Zielkunden-Webseite ein einzigartiges Kundenprofil. Dieses Profil ist reich an überzeugenden Verkaufsargumenten und beinhaltet ein maßgeschneidertes Anschreiben, das perfekt auf die Bedürfnisse des Kunden zugeschnitten ist.",
    "imgAlt": "Eine Bildschirmaufnahme der Scanseite der Applikation",
    "imgTitle": "Scanseite",
    "theScan": "DER SCAN"
  }
}
</i18n>
