<template>
  <div
    id="contact"
    class="relative flex flex-col pb-4 landing-page-section bg-iq-grey-dark"
  >
    <a id="section-contact" class="absolute top-0 -mt-20" />
    <h2
      v-highlight-index="4"
      class="mt-12 text-center text-bold-64 lg:text-bold-108"
    >
      {{ title }}
    </h2>
    <div class="mt-10 text-center text-regular-20 lg:text-regular-32">
      {{ text }}
    </div>

    <div class="flex flex-col gap-5 mt-8 lg:mt-8">
      <form
        class="flex-col flex items-center w-full h-full justify-center mt-4 lg:pb-4 pb-4 lg:grid grid-rows-2 grid-cols-[repeat(auto-fit,minmax(32%,1fr))] lg:gap-x-40 lg:gap-y-8 gap-y-1"
        @submit="onSubmit"
      >
        <template v-for="(field, index) in formFields" :key="index">
          <FormField
            v-slot="{ componentField }"
            :name="field.name"
            class="w-full"
            ><UiFormItem
              class="w-full"
              :class="{ 'col-span-2': field.name === 'message' ? true : false }"
            >
              <UiFormControl>
                <template v-if="field.name === 'message'">
                  <UiTextarea
                    v-model="messageText"
                    :type="field.type"
                    :placeholder="field.placeholder"
                    v-bind="componentField"
                    :class="field.class"
                  />
                </template>
                <template v-else>
                  <UiInput
                    :type="field.type"
                    :placeholder="field.placeholder"
                    v-bind="componentField"
                    :class="field.class"
                  /> </template
              ></UiFormControl>
              <UiFormMessage />
            </UiFormItem>
          </FormField>
        </template>
        <UiButton
          :disabled="isLoading || isEmpty"
          class="iq-button-success lg:max-w-[60%] justify-self-center lg:col-span-full mt-12 lg:mt-4 w-full"
        >
          <TheSpinner v-if="isLoading" />
          <span v-if="!isLoading"> {{ t('form.submit') }} </span>
        </UiButton>
      </form>
      <span
        v-if="isSent"
        class="text-regular-18 text-center !text-iq-red w-full"
        >{{ t('message_sent') }}</span
      >
    </div>
  </div>
</template>

<script lang="ts" setup>
import { FormField } from '@/components/ui/form';
import { toTypedSchema } from '@vee-validate/zod';
import { useReCaptcha } from 'vue-recaptcha-v3';
import { toast } from 'vue-sonner';
import * as z from 'zod';

const { t } = useI18n();

const formFields = computed(() => [
  { name: 'name', type: 'text', placeholder: 'Name *', class: '' },
  {
    name: 'company',
    type: 'text',
    placeholder: t('label.placeholder.company'),
    class: 'w-full my-4 lg:my-0',
  },
  {
    name: 'email',
    type: 'email',
    placeholder: t('label.placeholder.email'),
    class: '!py-0',
  },
  {
    name: 'phone',
    type: 'phone',
    placeholder: 'Telefon',
    class: 'w-full mt-4 lg:mt-0',
  },
  {
    name: 'message',
    type: 'text',
    placeholder: t('label.placeholder.message'),
    class: 'w-full mt-4 lg:mt-0 !bg-iq-white !min-h-[160px] text-iq-beige',
  },
]);

const isLoading = ref(false);
const messageText = ref('');
const isSent = ref(false);
const recaptcha = useReCaptcha();
const text = computed(() => t('text'));
const title = computed(() => t('title'));
const formSchema = toTypedSchema(
  z.object({
    email: z
      .string({
        required_error: t('form.email.required'),
      })
      .email({ message: t('form.email.invalid') }),
    name: z.string({
      required_error: t('form.name.required'),
    }),
    message: z.string({ required_error: t('form.message.required') }),
    company: z.string().optional(),
    phone: z.string().optional(),
  })
);

const isEmpty = computed(() => {
  return messageText.value.trim() === '';
});
const { handleSubmit, resetForm } = useForm({
  validationSchema: formSchema,
});

const onSubmit = handleSubmit(async (values) => {
  const { name, email, company, phone, message } = values;

  trackAtServer('contact');

  try {
    isLoading.value = true;

    await recaptcha?.recaptchaLoaded();
    const token = await recaptcha?.executeRecaptcha('contact');

    await $fetch('/api/contact', {
      body: {
        name,
        email,
        company,
        phone,
        message,
        type: 'CONTACT',
      },
      method: 'POST',
      headers: { 'X-Recaptcha-Token': token ?? '' },
      retry: false,
    });

    resetForm();
    messageText.value = '';
    isSent.value = true;
  } catch {
    toast.error(t('toast_error'), {
      duration: 1000000,
    });
    clearError();
  } finally {
    isLoading.value = false;
  }
});
</script>

<i18n lang="json">
{
  "en": {
    "form": {
      "email": {
        "invalid": "Invalid email address",
        "required": "Please enter an email address"
      },
      "message": {
        "required": "Please enter a message"
      },
      "name": {
        "required": "Please enter a name"
      },
      "submit": "SUBMIT"
    },
    "label": {
      "placeholder": {
        "company": "Company",
        "email": "Email *",
        "phone": "Phone",
        "message": "What's on your mind?"
      }
    },
    "message_sent": "Your message has been sent. We will get back to you shortly!",
    "text": "If you have any questions about our services or about us, we are happy to assist you personally. Write to us using the form and we will respond as soon as possible.",
    "title": "CONTACT",
    "toast_error": "An error occurred. Please try again"
  },
  "de": {
    "form": {
      "email": {
        "invalid": "Keine gültige E-Mail-Adresse",
        "required": "Bitte E-Mail-Adresse eingeben"
      },
      "message": {
        "required": "Bitte Nachricht eingeben"
      },
      "name": {
        "required": "Bitte Namen eingeben"
      },
      "submit": "SENDEN"
    },
    "label": {
      "placeholder": {
        "company": "Firma",
        "email": "E-Mail *",
        "phone": "Telefon",
        "message": "Was liegt dir auf dem Herzen?"
      }
    },
    "message_sent": "Deine Nachricht wurde versendet. Wir melden uns in Kürze bei dir!",
    "text": "Bei Fragen rund um unsere Dienstleistungen oder uns selbst, stehen wir dir gerne persönlich zur Verfügung. Schreib uns über das Formular und wir antworten schnellstmöglich.",
    "title": "KONTAKT",
    "toast_error": "Ein Fehler ist aufgetreten. Bitte versuche es noch einmal"
  }
}
</i18n>
