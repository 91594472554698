<template>
  <div
    class="relative flex flex-col w-full pt-12 lg:gap-20 lg:flex-row items-center"
  >
    <!-- <div class="lg:w-[220%] h-50 lg:h-100 w-full items-center"> -->
    <!-- <div
        class="w-full h-full bg-center bg-no-repeat bg-contain"
        :style="{ backgroundImage: `url(${image})` }"
      ></div> -->
    <NuxtImg
      format="webp"
      :width="props.imgWidth"
      :height="props.imgHeight"
      :src="props.image"
      loading="lazy"
      fit="outside"
      :alt="props.imgAlt"
      :title="props.imgTitle"
      placeholder
    />
    <!-- </div> -->
    <div class="lg:flex-col lg:flex lg:mt-8 lg:max-w-[60%] lg:min-w-[50%]">
      <div class="hidden lg:flex text-bold-84 whitespace-nowrap">
        {{ title }}
      </div>
      <!-- eslint-disable vue/no-v-html -->
      <div
        class="mx-8 mt-8 lg:mx-0 text-regular-20 lg:text-regular-22"
        v-html="text"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
const props = defineProps<{
  title: string;
  image: string;
  imgHeight: string;
  imgWidth: string;
  text: string;
  imgAlt: string;
  imgTitle: string;
}>();
</script>
