<template>
  <TheTabsContent
    title="CREDITS"
    :image="imgTabsScan"
    img-height="150"
    img-width="150"
    :text="text"
    :img-alt="imgAlt"
    :img-title="imgTitle"
  />
</template>
<script lang="ts" setup>
import imgTabsScan from '/images/tabs-credits.webp';
const { t } = useI18n({
  useScope: 'local',
});
const text = computed(() => t('creditsText'));
const imgAlt = computed(() => t('imgAlt'));
const imgTitle = computed(() => t('imgTitle'));
</script>

<i18n lang="json">
{
  "en": {
    "creditsText": "You can adjust the number of your affordable SCAN credits in your account at any time. This way, you always have a good overview of costs and budget.",
    "imgAlt": "An icon symbolizing the recharging of credits",
    "imgTitle": "Credits Icon"
  },
  "de": {
    "creditsText": "Du kannst in deinem Account jederzeit die Zahl deiner kostengünstigen SCAN-Credits anpassen. Somit hast du immer einen guten Kosten- und Budgetüberblick.",
    "imgAlt": "Eine Icon, dass das Aufladen von Credits symbolisiert",
    "imgTitle": "Creditsicon"
  }
}
</i18n>
