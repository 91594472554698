<template>
  <form class="flex flex-col align-center">
    <FormField
      v-slot="{ componentField, errorMessage }"
      name="companyUrl"
      class="w-full"
      ><UiFormItem class="w-full">
        <UiFormControl>
          <AppScanInput
            id="base"
            :disabled="isScanning"
            :is-base-profile="true"
            :is-loading="isLoadingOwn"
            :model-value="valueCompany"
            :product="companyStore.baseProduct"
            :products="companyStore.detailedProducts"
            :profile="(currentScan.companyProfile as Profile)"
            :profiles="companyStore.ownProfiles"
            :progress-text="progressTextOwn"
            :progress="progressOwn"
            class="w-full"
            :placeholder="t('placeholder.company-url')"
            v-bind="componentField"
            :error="baseCompanyError"
            @update:model-value="setValueOwnLink"
          />

          <!-- Workaround for elements height is changing on vee validate error  -->
          <span v-if="!errorMessage" class="block text-transparent min-h-4">
            ERRORPLACEHOLDER
          </span>
        </UiFormControl>
        <UiFormMessage />
      </UiFormItem>
    </FormField>

    <FormField
      v-slot="{ componentField, errorMessage }"
      name="targetUrl"
      class="w-full"
      ><UiFormItem class="w-full">
        <UiFormControl>
          <AppScanInput
            id="target"
            :disabled="isScanning"
            :is-base-profile="false"
            :is-loading="isLoadingExternal"
            :model-value="valueTarget"
            :profile="companyStore.targetCompany"
            :profiles="companyStore.targetProfiles"
            :progress="progressTarget"
            :progress-text="progressTextTarget"
            class="w-full mb-2"
            :placeholder="t('placeholder.target-url')"
            v-bind="componentField"
            :error="targetCompanyError"
            @update:model-value="setValueTargetLink"
          />
          <!-- Workaround for elements height is changing on vee validate error  -->
          <span v-if="!errorMessage" class="block text-transparent min-h-4">
            ERRORPLACEHOLDER
          </span>
        </UiFormControl>
        <UiFormMessage />
      </UiFormItem>
    </FormField>
    <TheLanguageSelect v-model="selectedLanguage" class="mb-4" />

    <UiButton
      type="button"
      class="self-center !iq-button-success w-full mt-1"
      :disabled="
        isScanning ||
        !!errors.companyUrl ||
        !!errors.targetUrl ||
        !valueCompany ||
        !valueTarget
      "
      :class="{
        '!iq-button-cancel': textLoadingGeneral,
      }"
      @click="matchProfiles()"
    >
      <div v-if="!textLoadingGeneral" class="flex self-center">
        {{ t('button.scan') }}
      </div>
      <div
        v-if="textLoadingGeneral"
        class="flex !tracking-[0px] self-center !text-iq-red"
      >
        {{ textLoadingGeneral }}
      </div>
    </UiButton>
    <div
      class="inline py-1 text-regular-10 !text-iq-text-subtle text-left mt-1"
    >
      {{ t('label.recaptcha-protection') }}
      <a class="underline" href="https://policies.google.com/privacy">{{
        t('label.privacy-policy')
      }}</a>
      {{ t('label.and') }}
      <a class="underline" href="https://policies.google.com/terms">{{
        t('label.terms-of-service')
      }}</a>
      {{ t('label.apply') }}
    </div>
  </form>
</template>

<script setup lang="ts">
import { FormField } from '@/components/ui/form';
import type { Profile } from '@prisma/client';
import { toTypedSchema } from '@vee-validate/zod';
import { useReCaptcha } from 'vue-recaptcha-v3';
import { toast } from 'vue-sonner';
import * as z from 'zod';
import { ErrorCode } from '~/enum/error-code.enum';
import { Language } from '~/enum/language.enum';
import trackAtServer from '~/utils/trackAtServer';

const { t, locale } = useI18n({
  useScope: 'local',
});

const selectedLanguage = ref<Language>(
  (locale.value.toUpperCase() as Language) ?? Language.EN
);

const baseCompanyError = ref(false);
const hasScanned = reactive<{
  scanned: string;
  maxAge: number;
  expires: string;
}>({
  scanned: 'false',
  maxAge: 31536000,
  expires: new Date(Date.now() + 31536000 * 1000).toUTCString(),
});
const hasScannedCookie = useCookie<{
  scanned: string;
  maxAge: number;
  expires: string;
}>('hasScannedUser');
const isScanning = ref(false);
const progressIntervalBase = ref();
const progressIntervalTarget = ref();
const recaptcha = useReCaptcha();
const scan = useScan();
const targetCompanyError = ref(false);
const wakeLock = useWakeLock();
const { activeScan } = storeToRefs(useScanStore());
const { emitter, currentScan } = toReactive(scan);
const emit = defineEmits<{
  'scan:compatibility': [value?: string];
  'scan:complete': [value?: string];
  'scan:error': [value?: string];
  'scan:init': [value?: ScanProfiles];
  'scan:letter': [value?: string];
  'scan:letter:finish': [value?: string];
  'scan:salestext': [value?: string];
  'scan:start': [value?: ScanProfiles];
  'scan:cancelled': [];
  'scan:profile:start': [value?: string];
  'scan:profile:finish': [value?: { profile: Profile; isBaseProfile: boolean }];
  'scan:hasScannedUser': [];
}>();

emitter.on('scan:complete', () => {
  releaseWakeLock();
  hasScanned.scanned = 'true';
  hasScannedCookie.value = hasScanned;
  textLoadingGeneral.value = t('messages.scan-completed');
});
emitter.on('scan:error', () => {
  releaseWakeLock();
});

emitter.on('scan:start', (loadingText: string) => {
  if (wakeLock.isSupported.value) {
    wakeLock.request('screen');
  }
  textLoadingGeneral.value = loadingText;
  isScanning.value = true;
  emit('scan:start');
});

emitter.on('scan:profile:start', (args: { isBaseProfile: boolean }) => {
  const { isBaseProfile } = args;
  if (isBaseProfile) {
    isLoadingOwn.value = true;
    progressIntervalBase.value = setProgressInterval(isBaseProfile);
    return;
  }
  isLoadingExternal.value = true;
  progressIntervalTarget.value = setProgressInterval(isBaseProfile);
});

emitter.on(
  'scan:profile:finish',
  (args: { isBaseProfile: boolean; profile: Profile }) => {
    const { isBaseProfile, profile } = args;
    emit('scan:profile:finish', args);
    if (isBaseProfile) {
      finishProgress(isBaseProfile, progressIntervalBase.value);
      valueCompany.value = profile.name;
      writeToStorage(
        'companyProfile',
        JSON.stringify({ ...profile, url: valueCompany.value })
      );
      return;
    }
    valueTarget.value = profile.name;
    writeToStorage(
      'targetProfile',
      JSON.stringify({ ...profile, url: valueTarget.value })
    );
    finishProgress(isBaseProfile, progressIntervalTarget.value);
  }
);

emitter.on(
  'scan:profile:error',
  (
    args: { isBaseProfile: boolean },
    error: { data: { status: number; statusMessage: string } }
  ) => {
    const { isBaseProfile } = args;
    if (isBaseProfile) {
      baseCompanyError.value = true;
    } else {
      targetCompanyError.value = true;
    }
    isLoadingOwn.value = false;
    isLoadingExternal.value = false;
    isScanning.value = false;
    textLoadingGeneral.value = '';
    finishProgress(isBaseProfile, progressIntervalBase.value);
    finishProgress(isBaseProfile, progressIntervalTarget.value);
    toast.error(t(error?.data?.statusMessage), {
      duration: 1000000,
    });
    clearError();
    emit('scan:error');
    releaseWakeLock();
  }
);

emitter.on('scan:compatibility', async () => {
  textLoadingGeneral.value = t('messages.scan-compatibility');
  emit('scan:compatibility');
});
emitter.on('scan:compatibility:fail', async () => {
  writeToStorage(
    'compatibility',
    activeScan.value.resultTexts.compatibilityReason
  );
  emit('scan:letter:finish');
});
emitter.on('scan:salestext:start', () => {
  textLoadingGeneral.value = t('messages.scan-sales-text');
  emit('scan:salestext');
});
emitter.on('scan:salestext:finish', () => {
  writeToStorage('salesText', activeScan.value.resultTexts.whatWhy);
});
emitter.on('scan:letter:start', () => {
  textLoadingGeneral.value = t('messages.scan-letter');
  emit('scan:letter');
});
emitter.on('scan:letter:finish', () => {
  writeToStorage('letter', activeScan.value.resultTexts.salesText);
  emit('scan:letter:finish');
});
emitter.on('scan:init', (scan: ScanProfiles) => {
  emit('scan:init', scan);
});

const companyStore = useCompanyStore();
const isLoadingExternal = ref(false);
const isLoadingOwn = ref(false);
const progressOwn = ref(0);
const progressTarget = ref(0);
const progressTextOwn = ref('');
const progressTextTarget = ref('');
const textLoadingGeneral = ref('');
const valueTarget = ref('');
const valueCompany = ref('');

const invalidUrlScheme = z.custom<string>(
  (val) => {
    try {
      const casted = String(val);

      let urlToTest;
      if (!casted.startsWith('https://') && !casted.startsWith('http://')) {
        urlToTest = new URL(`https://${casted}`);
      } else {
        urlToTest = new URL(casted);
      }

      if (!urlToTest.hostname.includes('.')) {
        return false;
      }

      return true;
    } catch (e) {
      console.error(e);
      return false; // Validation fails
    }
  },
  {
    message: t('error.invalid-url'), // Default message if the refinement fails
  }
);

const formSchema = toTypedSchema(
  z.object({
    companyUrl: invalidUrlScheme,
    targetUrl: invalidUrlScheme,
  })
);

const { errors } = useForm({
  validationSchema: formSchema,
});

function writeToStorage(key: string, value: string) {
  localStorage.setItem(key, value);
}

function releaseWakeLock() {
  if (wakeLock.isSupported.value && wakeLock.isActive.value) {
    wakeLock.release();
  }
}

async function matchProfiles() {
  // type here is boolean but typescript says is can be just string, undefined or null(?)
  if (hasScannedCookie.value?.scanned === 'true') {
    return emit('scan:hasScannedUser');
  }
  baseCompanyError.value = false;
  targetCompanyError.value = false;

  try {
    await recaptcha?.recaptchaLoaded();
    if (!recaptcha) {
      throw createError({
        statusCode: 500,
        statusMessage: ErrorCode.RECAPTCHA,
      });
    }
    await scan.matchProfiles(recaptcha, selectedLanguage.value as Language);
  } catch (error) {
    console.error(error);
    isLoadingOwn.value = false;
    isLoadingExternal.value = false;
    isScanning.value = false;
    textLoadingGeneral.value = '';
    finishProgress(true, progressIntervalBase.value);
    finishProgress(false, progressIntervalTarget.value);

    trackAtServer('scan_landing_error', {
      baseCompanyError: baseCompanyError.value,
      targetCompanyError: targetCompanyError.value,
    });

    emit('scan:error');
  }
}

function finishProgress(isBaseCompany: boolean, interval: NodeJS.Timeout) {
  const isLoading = isBaseCompany ? isLoadingOwn : isLoadingExternal;
  const progress = isBaseCompany ? progressOwn : progressTarget;
  const progressText = isBaseCompany ? progressTextOwn : progressTextTarget;
  // fill progress bar if its not completely filled yet
  const finishInterval = setInterval(() => {
    if (progress.value < 100) {
      progress.value += 5;
    } else {
      setTimeout(() => {
        isLoading.value = false;
        clearInterval(finishInterval);
        clearProgressStatus(interval, isLoading, progress, progressText);
      }, 500);
    }
  }, 10);
}

function setValueOwnLink(value: string) {
  scan.setCompanyUrl(value);
  valueCompany.value = value;
}

function setValueTargetLink(value: string) {
  scan.setTargetUrl(value);
  valueTarget.value = value;
}

function clearProgressStatus(
  interval: NodeJS.Timeout,
  isLoading: Ref<boolean>,
  progress: Ref<number>,
  progressText: Ref<string>
) {
  clearInterval(interval);
  isLoading.value = false;
  progressText.value = '';
  progress.value = 0;
}

function setProgressInterval(isBaseCompany: boolean) {
  const text = isBaseCompany ? progressTextOwn : progressTextTarget;
  const progress = isBaseCompany ? progressOwn : progressTarget;
  const durationMs = Math.random() * (60000 - 45000) + 45000;
  const timeoutMs = durationMs / 100;
  return setInterval(() => {
    text.value = t('progress[0]');
    if (progress.value < 90) {
      progress.value += 1;
    }

    if (progress.value > 20) {
      text.value = t('progress[1]');
    }
    if (progress.value > 40) {
      text.value = t('progress[2]');
    }
  }, timeoutMs);
}

function handleBeforeUnload() {
  trackAtServer('landing_page_leave');

  if (isScanning.value) {
    trackAtServer('scan_landing_page_cancel_leave');
  }
}

onMounted(() => {
  window.addEventListener('beforeunload', handleBeforeUnload);
});

onBeforeUnmount(() => {
  window.removeEventListener('beforeunload', handleBeforeUnload);
});
</script>
<i18n lang="json">
{
  "de": {
    "label": {
      "recaptcha-protection": "Diese Website ist durch reCAPTCHA geschützt und es gelten die Google",
      "privacy-policy": "Datenschutzbestimmungen",
      "and": "und",
      "terms-of-service": "Nutzungsbedingungen",
      "apply": ""
    },
    "button": {
      "scan": "SCAN",
      "login-for-more-scans": "Für weitere Scans anmelden"
    },
    "placeholder": {
      "company-url": "Deine Firmen Webseite",
      "target-url": "Deine Zielkunden Webseite"
    },
    "error": {
      "invalid-url": "Bitte gültige URL eingeben"
    },
    "messages": {
      "scan-completed": "Für weitere Scans anmelden",
      "scan-compatibility": "Kompatibilität wird ermittelt",
      "scan-sales-text": "Salesargumentation wird erstellt",
      "scan-letter": "Anschreiben wird erstellt"
    },
    "progress": [
      "Relevante Links werden gesucht",
      "Websitedaten werden ausgelesen",
      "Firmenprofil wird erstellt"
    ]
  },
  "en": {
    "label": {
      "recaptcha-protection": "This website is protected by reCAPTCHA and the Google",
      "privacy-policy": "Privacy Policy",
      "and": "and",
      "terms-of-service": "Terms of Service",
      "apply": "apply"
    },
    "button": {
      "scan": "SCAN",
      "login-for-more-scans": "Login for more scans"
    },
    "placeholder": {
      "company-url": "Your company website",
      "target-url": "Your target customer website"
    },
    "error": {
      "invalid-url": "Please enter a valid URL"
    },
    "messages": {
      "scan-completed": "Login for more scans",
      "scan-compatibility": "Compatibility will be determined",
      "scan-sales-text": "Sales text will be created",
      "scan-letter": "Letter will be created"
    },
    "progress": [
      "Search for relevant links",
      "Read website data",
      "Create company profile"
    ]
  }
}
</i18n>
