<template>
  <UiCollapsible v-model:open="isOpen">
    <div
      class="flex flex-col items-center justify-between w-full"
      :class="{ 'pb-12 ': !isOpen }"
    >
      <UiCollapsibleTrigger as-child>
        <UiButton
          variant="ghost"
          class="p-0 flex flex-1 lg:max-w-full items-center text-left hover:!bg-transparent mx-2 w-full justify-normal"
        >
          <Icon
            class="flex-shrink-0 w-4 h-4 mr-4"
            :name="`${
              isOpen ? 'heroicons:chevron-up' : 'heroicons:chevron-down'
            }`"
          />
          <h4
            class="truncate text-bold-26 lg:text-bold-34 max-w-[80%] min-w-[80%] lg:w-full overflow-visible"
          >
            {{ props.title }}
          </h4>
        </UiButton>
      </UiCollapsibleTrigger>
    </div>
    <transition name="collapsible">
      <UiCollapsibleContent>
        <div
          ref="scrollContainer"
          class="flex flex-row gap-2 mt-4 overflow-x-auto overflow-y-hidden lg:hidden px-9 no-scrollbar snap-start snap-mandatory last:pr-4 snap snap-x"
          @scroll="onScrollSetIndicator"
        >
          <div
            v-for="(text, index) in linkedInTexts"
            :key="index"
            class="w-full min-w-[100dvw] items-center h-[280px] flex flex-row place-content-center"
          >
            <div
              class="flex-1 border rounded-lg max-w-70 max-h-70 min-h-70 border-iq-white snap-center m-4"
            >
              <div class="p-4 overflow-auto whitespace-pre-wrap h-55">
                {{ text.text }}
              </div>
            </div>
          </div>
        </div>
        <AppIndexIndicator
          v-if="linkedInTexts?.length"
          class="h-10 lg:hidden"
          :total-indices="linkedInTexts?.length"
          :active-index="activeTabIndex"
        />

        <div class="justify-around hidden w-full gap-4 mt-4 lg:flex">
          <template v-for="(text, index) in linkedInTexts" :key="index">
            <div
              class="flex-1 border rounded-lg max-w-70 max-h-70 min-h-70 border-iq-white"
            >
              <div class="p-4 overflow-auto whitespace-pre-wrap h-55">
                {{ text.text }}
              </div>
            </div>
          </template>
        </div>
      </UiCollapsibleContent>
    </transition>
  </UiCollapsible>
</template>

<script setup lang="ts">
import type { ResultText } from '@prisma/client';

const isOpen = ref(false);
const scrollContainer = ref<HTMLDivElement>();
const activeTabIndex = ref(0);
const props = defineProps<{
  title: string;
  linkedInTexts: ResultText[];
  initOpen?: boolean;
}>();

function onScrollSetIndicator() {
  if (!scrollContainer.value) {
    return;
  }
  const scrollPosition = scrollContainer.value.scrollLeft;

  if (scrollContainer.value.children[0]) {
    const cardWidth = (scrollContainer.value.children[0] as HTMLElement)
      .offsetWidth;

    const cardIndex = Math.round(scrollPosition / cardWidth);
    activeTabIndex.value = cardIndex + 1;
  }
}

defineExpose({
  isOpen,
});

onBeforeMount(() => {
  if (props.initOpen) {
    isOpen.value = true;
  }
});
</script>

<style scoped></style>
