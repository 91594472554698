<template>
  <div
    id="about-us"
    class="relative flex flex-col pb-4 landing-page-section bg-iq-grey-light"
  >
    <a id="section-about-us" class="absolute top-0 -mt-20" />

    <h2 class="w-full text-center mt-12">
      <span
        :key="locale"
        v-highlight-index="locale === 'de' ? 2 : 0"
        class="text-bold-64 lg:text-bold-108"
      >
        {{ t('heading') }}
      </span>
    </h2>

    <div class="flex flex-col lg:hidden">
      <!-- <h2>
        <span class="mt-12 text-bold-64 lg:text-bold-108" v-highlight-index="1">
          DAS SIND
        </span>
        <span class="text-bold-64 lg:text-bold-108" v-highlight-index="1">
          WIR</span
        >
      </h2> -->
      <div class="mt-10 text-regular-20 lg:text-regular-32">
        {{ text }}
      </div>
      <div
        ref="scrollContainer"
        class="flex flex-row h-full gap-10 mt-12 overflow-x-auto overflow-y-hidden px-9 no-scrollbar snap-start snap-mandatory last:pr-4 snap snap-x"
        @scroll="onScrollSetIndicator"
      >
        <div
          v-for="(img, index) in imageArr"
          :key="index"
          class="w-full min-w-[100dvw] items-center h-[320px] flex flex-row place-content-center"
        >
          <!-- <div
            :key="index"
            class="img-round w-[300px] h-[300px] snap-center aspect-square"
            :style="{
              backgroundImage: `url(${img})`,
            }"
          ></div> -->
          <NuxtImg
            :key="index"
            loading="lazy"
            format="webp"
            class="img-round snap-center aspect-square"
            width="300px"
            height="300px"
            :src="img.image"
            placeholder
            :title="img.title"
            :alt="img.alt"
          />
        </div>
      </div>
      <AppIndexIndicator
        class="mt-6 lg:hidden"
        :total-indices="imageArr.length"
        :active-index="activeTabIndex"
      />

      <div
        class="mt-8 lg:pb-20 pb-10 grid grid-cols-[repeat(auto-fit,minmax(140px,1fr))] gap-4"
      >
        <div
          v-for="(fact, index) in numberFacts"
          :key="`fact-${index}`"
          class="flex flex-col items-center justify-center mt-8"
        >
          <div class="mb-2 number-value text-bold-64">{{ fact.value }}</div>
          <div class="number-label text-regular-20">{{ fact.label }}</div>
        </div>
      </div>
    </div>
    <div class="flex-row hidden lg:flex">
      <div class="max-w-[42%]">
        <!-- <h2 class="hidden lg:block">
          <span
            class="mt-12 text-bold-64 lg:text-bold-108"
            v-highlight-index="1"
          >
            DAS
          </span>
          <span class="text-bold-64 lg:text-bold-108"> SIND </span>
          <span class="text-bold-64 lg:text-bold-108" v-highlight-index="1">
            WIR</span
          >
        </h2> -->
        <div class="text-regular-20 lg:text-regular-32 lg:w-[462px] mt-38">
          {{ text }}
        </div>
      </div>
      <div class="flex flex-col w-full h-full">
        <div
          class="grid flex-1 w-full grid-cols-2 grid-rows-2 gap-4 mt-10 px-28 place-items-center max-w-[900px]"
        >
          <div
            v-for="(i, index) in imageArr"
            :key="index"
            class="w-full h-[320px] flex flex-row"
          >
            <NuxtImg
              :key="index"
              loading="lazy"
              format="webp"
              class="absolute img-round aspect-square"
              width="300px"
              height="300px"
              :class="i.classes"
              :src="i.image"
              :title="i.title"
              :alt="i.alt"
              placeholder
            />
            <!-- <div
              :key="index"
              class="absolute img-round aspect-square"
              :class="i.classes"
              :style="{ backgroundImage: `url(${i.image})` }"
            ></div> -->
          </div>
        </div>

        <div class="flex flex-row justify-between gap-8 mt-8 ml-8">
          <div
            v-for="(fact, index) in numberFacts"
            :key="`fact-${index}`"
            class="flex flex-col items-center justify-center"
          >
            <div class="mb-2 number-value text-bold-64 lg:text-bold-84">
              {{ fact.value }}
            </div>
            <div class="number-label text-regular-20 lg:text-regular-32">
              {{ fact.label }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref } from 'vue';

const { t, locale } = useI18n();

const numberFacts = computed(() => [
  {
    label: t('facts.employees'),
    value: '12',
  },
  {
    label: t('facts.ideas'),
    value: '10¹⁰',
  },
  {
    label: t('facts.curses'),
    value: '127',
  },
  {
    label: t('facts.projects'),
    value: '400+',
  },
]);

const imageArr = computed(() => [
  {
    image: '/images/code.webp',
    classes: 'min-h-[24%] ml-20 mt-20',
    alt: t('images.code.alt'),
    title: t('images.code.title'),
  },
  {
    image: '/images/dom.webp',
    classes: 'min-h-[24%] mt-12 min-w-[24%]',
    alt: t('images.dom.alt'),
    title: t('images.dom.title'),
  },
  {
    image: '/images/mitarbeiter.webp',
    classes: 'min-w-[15%] min-h-[15%] ml-34 -mt-4 absolute',
    alt: t('images.employees.alt'),
    title: t('images.employees.title'),
  },
]);

const scrollContainer = ref<HTMLDivElement>();
const activeTabIndex = ref(1);

const text = computed(() => t('text'));

function onScrollSetIndicator() {
  if (!scrollContainer.value) {
    return;
  }
  const scrollPosition = scrollContainer.value?.scrollLeft;

  const cardWidth = (scrollContainer.value.children[0] as HTMLElement)
    .offsetWidth;

  const cardIndex = Math.round(scrollPosition / cardWidth);
  activeTabIndex.value = cardIndex + 1;
}
</script>
<i18n lang="json">
{
  "en": {
    "facts": {
      "employees": "Employees",
      "ideas": "Ideas",
      "curses": "Curses",
      "projects": "Projects"
    },
    "heading": "ABOUT US",
    "images": {
      "code": {
        "alt": "A picture of a programmer standing in front of a screen. The image of the screen displays bytecode",
        "title": "Code"
      },
      "dom": {
        "alt": "A picture of Cologne Cathedral during sunset",
        "title": "Cologne Cathedral during sunset"
      },
      "employees": {
        "alt": "A picture of a company's employees",
        "title": "Employees"
      }
    },
    "text": "We are IQ-ME, the software company behind B2BSCAN, with over 20 years of experience experience in the development of complex software solutions. Our customers include numerous large and medium-sized companies, such as Zeppelin-Caterpillar, Mitsubishi Electric and Carglass."
  },
  "de": {
    "facts": {
      "employees": "Mitarbeiter",
      "ideas": "Ideen",
      "curses": "Flüche",
      "projects": "Projekte"
    },
    "heading": "ÜBER UNS",
    "images": {
      "code": {
        "alt": "Ein Bild von einem Programmierer, der vor einer Leinwand steht. Das Bild der Leinwand zeigt Bytecode an",
        "title": "Code"
      },
      "dom": {
        "alt": "Ein Bild vom Kölner Dom während des Sonnenunterganges",
        "title": "Kölner Dom während Sonnenuntergang"
      },
      "employees": {
        "alt": "Ein Bild von Mitarbeitern eines Unternehmens",
        "title": "Mitarbeiter"
      }
    },
    "text": "Wir sind IQ-ME, das Softwareunternehmen hinter B2BSCAN, mit über 20 Jahren Erfahrung in der Entwicklung komplexer Softwarelösungen. Zu unseren Kunden gehören zahlreiche große und mittelständische Unternehmen, wie z.B. Zeppelin-Caterpillar, Mitsubishi Electric und Carglass."
  }
}
</i18n>
