<template>
  <div id="features" class="relative landing-page-section bg-iq-grey-light">
    <a id="section-features" class="absolute top-0 -mt-20" />
    <h2
      v-highlight-index="2"
      class="mt-12 text-bold-64 lg:text-bold-108 text-center"
    >
      {{ t('title') }}
    </h2>
    <TheTabs
      class="mt-8"
      :tab-labels="tabLabels"
      :tab-contents="[
        TheTabsContentScan,
        TheTabsContentProfile,
        TheTabsContentLeads,
        TheTabsContentArchiv,
        TheTabsContentCredits,
      ]"
    />
  </div>
</template>
<script lang="ts" setup>
import TheTabsContentArchiv from '@/components/TheTabsContentArchiv.vue';
import TheTabsContentCredits from '@/components/TheTabsContentCredits.vue';
import TheTabsContentLeads from '@/components/TheTabsContentLeads.vue';
import TheTabsContentProfile from '@/components/TheTabsContentProfile.vue';
import TheTabsContentScan from '@/components/TheTabsContentScan.vue';

const { t } = useI18n();
const tabLabels = computed(() => [
  t('theScan'),
  t('scanProfile'),
  t('leads'),
  t('archiv'),
  t('credits'),
]);
</script>

<i18n lang="json">
{
  "en": {
    "title": "FEATURES",
    "theScan": "THE SCAN",
    "scanProfile": "SCANPROFILE",
    "leads": "LEADS",
    "archiv": "ARCHIVE",
    "credits": "CREDITS"
  },
  "de": {
    "title": "FEATURES",
    "theScan": "DER SCAN",
    "scanProfile": "SCANPROFIL",
    "leads": "LEADS",
    "archiv": "ARCHIV",
    "credits": "CREDITS"
  }
}
</i18n>
