<template>
  <TheTabsContent
    title="LEADS"
    :image="imgTabsScan"
    img-height="150"
    img-width="150"
    :text="text"
    :img-alt="imgAlt"
    :img-title="imgTitle"
  />
</template>
<script lang="ts" setup>
import imgTabsScan from '/images/tabs-leads.webp';
const { t } = useI18n({
  useScope: 'local',
});
const text = computed(() => t('leadsText'));
const imgAlt = computed(() => t('imgAlt'));
const imgTitle = computed(() => t('imgTitle'));
</script>

<i18n lang="json">
{
  "en": {
    "leadsText": "In the Leads section, you can upload link lists of your target customers to be acquired and scan them directly.",
    "imgAlt": "A screenshot of the application's Leads page",
    "imgTitle": "Leads Page"
  },
  "de": {
    "leadsText": "Im Bereich Leads kannst du Linklisten deiner zu akquirierenden Zielkunden hochladen und diese direkt scannen.",
    "imgAlt": "Eine Bildschirmaufnahme der Leadsseite der Applikation",
    "imgTitle": "Leadsseite"
  }
}
</i18n>
