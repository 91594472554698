<template>
  <TheDialog :open="props.isOpen">
    <template #header>
      <UiDialogTitle
        :class="'!text-bold-34 relative !text-iq-white p-6 items-end border-b border-b-iq-white justify-between flex '"
        ><div class="truncate !text-bold-34 tracking-iq-wide max-w-[90%]">
          {{ `${profiles[0].name} -  ${profiles[1].name}` }}
        </div>

        <Icon
          class="icon-iq text-iq-red"
          name="heroicons:x-mark-16-solid"
          @click="emit('close:dialog')"
        />
      </UiDialogTitle>
      <UiDialogDescription />
    </template>
    <template #content>
      <div ref="containerRef" class="p-4 lg:pt-0 !max-w-[100%] pb-12 mb-12">
        <div class="flex flex-col">
          <div class="text-bold-42 !text-iq-red">
            {{ t('header.profiles') }}
          </div>

          <div class="flex flex-col lg:flex-row">
            <template v-for="(profile, index) in profiles" :key="index"
              ><TheCollapsible
                v-if="profile?.description"
                :title="profile.name"
                :init-open="true"
                :show-copy="false"
                :show-download="false"
                :show-email="false"
                :text="profile.description"
                class="mt-4 !text-iq-white min-w-[50%]"
              />
            </template>
          </div>
        </div>
        <div class="mt-8 text-bold-42 !text-iq-red">
          {{ t('header.results') }}
        </div>

        <template v-for="(generated, index) in generatedTexts" :key="index">
          <TheCollapsible
            v-if="generated?.text"
            :ref="(el: Element | globalThis.ComponentPublicInstance | null) => assignElementToRef(el, generated.ref)"
            :title="generated.title"
            :show-copy="generated.showCopy"
            :result-text-type="generated.resultTextType"
            :show-download="generated.showCopy"
            :show-email="generated.showEmail"
            :text="generated.text"
            :init-open="generated.initOpen"
            :disable-modification="true"
            class="mt-4 !text-iq-white min-w-[50%]"
          />
        </template>

        <TheLinkedInCollapsible
          v-if="linkedInTexts?.length"
          class="my-4"
          :init-open="true"
          :title="t('linkedIn.text.header')"
          :linked-in-texts="linkedInTexts"
        />
      </div>
    </template>
  </TheDialog>
</template>

<script setup lang="ts">
import { ResultTextType } from '@/enum/result-text-type.enum';
import type { ResultText } from '@prisma/client';

const { t, locale } = useI18n({
  useScope: 'local',
});

const salesTextRef = ref();

const props = defineProps<{
  isOpen: boolean;
}>();

const emit = defineEmits<{
  (e: 'close:dialog'): void;
}>();

const linkedInTexts = computed(() => {
  return [
    { text: t('linkedInTexts[0]') },
    { text: t('linkedInTexts[1]') },
    { text: t('linkedInTexts[2]') },
  ] as ResultText[];
});

const profiles = computed(() => [
  {
    name: t('profiles.baseCompany.name'),
    description: t('profiles.baseCompany.description'),
  },
  {
    name: t('profiles.targetCompany.name'),
    description: t('profiles.targetCompany.description'),
  },
]);

const generatedTexts = computed(() => {
  return [
    {
      ref: 'whatWhyRef',
      text: locale.value === 'en' ? whatWhyEnglish : whatWhyGerman,
      resultTextType: ResultTextType.WHATWHY,
      title: t('generatedTexts.titles[0]'),
      showCopy: false,
      showDownload: false,
      showEmail: false,
      initOpen: true,
    },

    {
      ref: 'salesTextRef',
      text: locale.value === 'en' ? salesTextEnglish : salesTextGerman,
      resultTextType: ResultTextType.SALESTEXT,
      title: t('generatedTexts.titles[1]'),
      showCopy: false,
      showDownload: false,
      showEmail: false,
      initOpen: true,
    },
  ];
});

function assignElementToRef(
  el: Element | globalThis.ComponentPublicInstance | null,
  ref: string
) {
  if (!ref) {
    return;
  }
  if (ref === 'salesTextRef') {
    salesTextRef.value = el;
  }
}

const salesTextEnglish = `Dear Members of the Executive Board of Bayer AG,

I am reaching out to you today because I am convinced that b2bScan can revolutionize your efforts in customer acquisition and market expansion in the health and agriculture sectors. Bayer AG is globally recognized for combining science and innovation to improve people's lives—a mission that b2bScan supports by optimizing business processes.

Our revolutionary AI-powered platform, b2bScan, has been specifically developed to meet the needs of companies like Bayer AG, simplifying and making the customer acquisition process more efficient. In a time when every minute counts, b2bScan significantly accelerates the acquisition process: tasks that previously took hours are now completed by our tool in about three minutes. This is achieved through advanced analysis of your company website and target customer information to create precise company profiles, compatibility analyses, and personalized cover letters.

The benefits of b2bScan for Bayer AG are numerous and include significant time savings and cost reductions in customer acquisition. By using our platform, you can efficiently evaluate the compatibility between Bayer's products and services and potential new customers, while also creating personalized customer approaches that significantly increase your acquisition success rate. Moreover, b2bScan enables you to strategically and successfully tap into new markets and customer segments in the fast-paced health and agriculture sectors.

Our product understanding for b2bScan is based on the use of cutting-edge Artificial Intelligence, which allows even companies without special IT expertise to benefit from this advanced technology. We have already successfully collaborated with other companies in your industry, where we could see an increase in the efficiency of customer acquisition by up to 70%. These success stories testify to the effectiveness and added value that b2bScan can also provide for Bayer AG.

Partnering with b2bScan offers Bayer AG the opportunity to be at the forefront of digital innovation while pursuing a sustainable and cost-conscious acquisition strategy. Together, we can not only meet the challenges in the market but use them as opportunities for further development and growth of your company.

I warmly invite you to explore the potential of a collaboration between Bayer AG and b2bScan in a personal meeting. Let us walk new paths together to further strengthen Bayer AG's position in the health and agriculture sectors.

Yours sincerely,

[Your Name]
[Your Position]
[Your Contact Information]

P.S. I would be very grateful if this letter could be forwarded to the relevant department to arrange a meeting about our potential collaboration. Thank you in advance for your assistance and cooperation.
`;

const salesTextGerman = `Sehr geehrte Damen und Herren der Geschäftsleitung der Bayer AG,
ich wende mich heute an Sie, da ich fest davon überzeugt bin, dass b2bScan Ihre Bemühungen in den Bereichen Neukundengewinnung und Markterweiterung im Gesundheits- und Agrarwirtschaftssektor revolutionieren kann. Bayer AG steht weltweit für die Verbindung von Wissenschaft und Innovation, um das Leben der
Menschen zu verbessern – eine Mission, die auch b2bScan durch die Optimierung
von Geschäftsprozessen unterstützt.

Unsere revolutionäre, KI-gestützte Plattform b2bScan ist speziell für die Bedürfnisse von Unternehmen wie Bayer AG entwickelt worden, um die Neukundenakquise zu vereinfachen und effizienter zu gestalten. In einer Zeit, in der jede Minute zählt, ermöglicht b2bScan eine erhebliche Beschleunigung des Akquiseprozesses: Was bisher Stunden in Anspruch nahm, erledigt unser Tool in nur etwa drei Minuten. Dies wird durch eine fortschrittliche Analyse Ihrer Unternehmenswebsite sowie der Informationen Ihrer Zielkunden erreicht, um präzise Unternehmensprofile, Kompatibilitätsanalysen und personalisierte Anschreiben zu generieren.

Die Vorteile von b2bScan für Bayer AG sind vielfältig und umfassen unter anderem eine signifikante Zeitersparnis und Kostenreduktion bei der Neukundengewinnung. Durch den Einsatz unserer Plattform können Sie die Kompatibilität zwischen den Produkten und Dienstleistungen von Bayer und potentiellen Neukunden effizient evaluieren und zugleich personalisierte Kundenansprachen erstellen, die Ihre Erfolgsquote bei der Akquise deutlich erhöhen. Darüber hinaus ermöglicht Ihnen b2bScan, neue Märkte und Kundensegmente in den schnelllebigen Sektoren Gesundheit und Agrarwirtschaft zielgerichtet und erfolgreich zu erschließen. Unser Produktverständnis für b2bScan basiert auf der Nutzung modernster Künstlicher Intelligenz, die es selbst Unternehmen ohne spezielle IT-Expertise ermöglicht, von dieser fortschrittlichen Technologie zu profitieren. Wir haben bereits erfolgreich mit anderen Unternehmen in Ihrer Branche zusammengearbeitet, bei denen wir eine Steigerung der Effizienz der Neukundenakquise um bis zu 70% feststellen konnten. Diese Erfolgsbeispiele zeugen von der Effektivität und dem Mehrwert, den b2bScan auch für Bayer AG leisten kann.

Die Partnerschaft mit b2bScan bietet Bayer AG die Möglichkeit, an der Spitze der digitalen Innovation zu stehen und gleichzeitig eine nachhaltige und kostenbewusste Akquisitionsstrategie zu verfolgen. Gemeinsam können wir die Herausforderungen im Markt nicht nur bewältigen, sondern als Chancen für die Weiterentwicklung und das Wachstum Ihres Unternehmens nutzen. Ich lade Sie herzlich ein, die Potenziale einer Kooperation zwischen Bayer AG und b2bScan in einem persönlichen Gespräch näher zu beleuchten. Lassen Sie uns gemeinsam neue Wege gehen, um die Position von Bayer AG in den Bereichen Gesundheit und Agrarwirtschaft weiter zu stärken.

Mit freundlichen Grüßen,
[Dein Name]
[Deine Position]
[Deine Kontaktdaten]


P.S. Ich wäre Ihnen sehr dankbar, wenn dieser Brief an die zuständige Abteilung weitergeleitet werden könnte, um eine Besprechung zu unserer potenziellen Zusammenarbeit zu arrangieren. Vielen Dank im Voraus für Ihre Mithilfe und das Entgegenkommen.`;

const whatWhyEnglish = `b2bScan is a revolutionary AI-powered solution specifically designed to support companies in the B2B sector in acquiring new customers. This analysis focuses on how b2bScan can meet the specific requirements of Bayer AG and optimize their business processes. Introduction to b2bScan b2bScan offers an efficient and time-saving method for customer acquisition through the use of artificial intelligence. By analyzing the company's website and target customer information, the platform generates comprehensive reports within minutes, including the target customer's company profile, compatibility analyses, and personalized cover letters. This technology significantly reduces the time and cost compared to traditional methods. Bayer AG: An Ideal Customer for b2bScan Bayer AG, a global leader in health and agriculture, strives to improve people's lives through innovative products and solutions. With a decentralized organizational structure and various divisions such as development, human resources, sales, and marketing, Bayer operates in a dynamic and complex market environment. The challenges in the areas of pharmaceuticals, crop protection, and seeds require efficient and targeted customer acquisition to remain competitive globally. Application of b2bScan at Bayer b2bScan can support Bayer in several areas:

Efficiency Increase in Customer Acquisition: Through the rapid generation of target customer profiles and compatibility analysis, Bayer can efficiently identify and approach potential customers in the health and agriculture sectors.
Cost Reduction: Reducing the time from hours to minutes for creating customer profiles and preparing acquisition materials leads to significant cost savings.
Personalization of Customer Approach: The ability to generate customer-specific letters enables Bayer to communicate effectively and personally with potential customers.
Market Expansion: By using b2bScan, Bayer can enter new markets and customer segments in the health and agriculture sectors by specifically targeting companies that could benefit from their products.
Potential Objections and Solutions
A potential objection from Bayer could be concerns about the accuracy and relevance of the data generated by b2bScan. b2bScan uses advanced AI algorithms to ensure that the information provided is current, precise, and tailored to Bayer's specific needs. Furthermore, the platform allows for flexible adjustment of the scanning parameters to further increase accuracy.
Conclusion

The implementation of b2bScan offers Bayer AG a unique opportunity to revolutionize its customer acquisition. By optimizing the acquisition processes, reducing costs, and enabling personalized customer communication, Bayer can strengthen its position in the health and agriculture sectors. The partnership with b2bScan thus promises significant added value for both companies.`;

const whatWhyGerman = `b2bScan ist eine revolutionäre KI-gestützte Lösung, die speziell entwickelt wurde, um Unternehmen im B2B-Bereich bei der Neukundengewinnung zu unterstützen. Diese Analyse konzentriert sich darauf, wie b2bScan die spezifischen Anforderungen der Bayer AG erfüllen und deren Geschäftsprozesse optimieren kann.

Einführung in b2bScan
b2bScan bietet eine effiziente und zeitsparende Methode zur Neukundengewinnung durch die Nutzung künstlicher Intelligenz. Durch die Analyse der Unternehmenswebsite und Zielkundeninformationen generiert die Plattform innerhalb von Minuten umfassende Berichte, die das Unternehmensprofil des Zielkunden, Kompatibilitätsanalysen und personalisierte Anschreiben umfassen. Diese Technologie ermöglicht eine signifikante Reduzierung des Zeitaufwands und der Kosten im Vergleich zu traditionellen Methoden.

Bayer AG: Ein idealer Kunde für b2bScan 

Die Bayer AG, ein weltweit führendes Unternehmen in den Bereichen Gesundheit und Agrarwirtschaft, strebt danach, das Leben der Menschen durch innovative Produkte und Lösungen zu verbessern. Mit einer dezentralen Organisationsstruktur und verschiedenen Divisionen wie Entwicklung, Personalwesen, Vertrieb und Marketing ist Bayer in einem dynamischen und komplexen Marktumfeld tätig. Die Herausforderungen in den Bereichen Pharmazeutika, Pflanzenschutz und Saatgut erfordern eine effiziente und zielgerichtete Neukundengewinnung, um auf globaler Ebene wettbewerbsfähig zu bleiben.

Anwendung von b2bScan bei Bayer

b2bScan kann Bayer in mehreren Bereichen unterstützen:

1. Effizienzsteigerung in der Neukundengewinnung:

Durch die schnelle Generierung von Zielkundenprofilen und die Analyse der Kompatibilität kann Bayer potenzielle Kunden in den Bereichen Gesundheit und Agrarwirtschaft effizient identifizieren und ansprechen.

2. Kostensenkung:

Die Reduzierung des Zeitaufwands von Stunden auf Minuten für die Erstellung von Kundenprofilen und die Vorbereitung von Akquise-Materialien führt zu erheblichen Kosteneinsparungen.

3. Personalisierung der Kundenansprache: 

Die Möglichkeit, kundenspezifische Anschreiben zu generieren, ermöglicht Bayer eine personalisierte und effektive Kommunikation mit potenziellen Kunden.

4. Marktexpansion: 

Durch die Nutzung von b2bScan kann Bayer neue Märkte und Kundensegmente in den Bereichen Gesundheit und Agrarwirtschaft erschließen, indem es gezielt Unternehmen anspricht, die von ihren Produkten profitieren könnten.

Potenzielle Einwände und Lösungen

Ein potenzieller Einwand seitens Bayer könnte die Frage nach der Genauigkeit und Relevanz der von b2bScan generierten Daten sein. b2bScan nutzt fortschrittliche KI-Algorithmen, um sicherzustellen, dass die bereitgestellten Informationen aktuell, präzise und auf die spezifischen Bedürfnisse von Bayer zugeschnitten sind. Darüber hinaus ermöglicht die Plattform eine flexible Anpassung der Scan-Parameter, um die Genauigkeit weiter zu erhöhen.

Fazit

Die Implementierung von b2bScan bietet der Bayer AG eine einzigartige Gelegenheit, ihre Neukundengewinnung zu revolutionieren. Durch die Optimierung der Akquiseprozesse, die Reduzierung von Kosten und die Ermöglichung einer personalisierten Kundenansprache kann Bayer seine Position in den Bereichen Gesundheit und Agrarwirtschaft stärken. Die Partnerschaft mit b2bScan verspricht somit einen signifikanten Mehrwert für beide Unternehmen.`;
</script>

<i18n lang="json">
{
  "de": {
    "header": { "results": "Ergebnisse", "profiles": "Profile" },
    "linkedIn": {
      "text": {
        "header": "Textvorschläge zur LinkedIn-Vernetzung"
      }
    },
    "generatedTexts": {
      "titles": ["Nutzenargumentation", "Anschreiben", "Kompatibilität"]
    },
    "profiles": {
      "baseCompany": {
        "name": "B2BScan",
        "description": "b2bScan ist eine revolutionäre KI-gestützte Lösung, die speziell entwickelt wurde, um Unternehmen im B2B-Bereich bei der Neukundengewinnung zu unterstützen. Durch die einfache Eingabe der eigenen Unternehmenswebsite und der Zielkundeninformationen liefert b2bScan innerhalb von nur etwa 3 Minuten umfassende Informationen, darunter das Unternehmensprofil des Zielkunden, eine Analyse der Kompatibilität, Argumente für eine Zusammenarbeit und ein kundenspezifisches Anschreiben. Im Vergleich zur herkömmlichen Vorgehensweise, die bis zu 3 Stunden in Anspruch nehmen kann, bietet b2bScan eine signifikante Zeit- und Kostenersparnis. Nutzer können eigene Unternehmens-Scanprofile erstellen, um die grundsätzliche Unternehmenskompatibilität mit einem Zielkunden zu überprüfen, sowie Produkt-Scanprofile für gezielte Produktangebote. Darüber hinaus ermöglicht die Plattform das Hochladen einer Liste potenzieller Zielkunden für eine effiziente Akquise und bietet einen Archivbereich für den Zugriff auf durchgeführte Scans sowie eine flexible Anpassung des Scan-Volumens durch Credits. Der USP von b2bScan liegt in der Nutzung von KI zur Vereinfachung und Optimierung der Neukundenakquise und des Kundenausbaus, ohne dass spezielle Fachkenntnisse erforderlich sind. Die Plattform steigert die Effizienz, reduziert Kosten und ermöglicht eine breite Nutzerzugänglichkeit innerhalb eines Unternehmens."
      },
      "targetCompany": {
        "name": "Bayer AG",
        "description": "Bayer ist ein weltweit tätiges Unternehmen in den Bereichen Gesundheit und Agrarwirtschaft. Mit dem Ziel, das Leben der Menschen zu verbessern, entwickelt Bayer innovative Produkte und Lösungen. Das Unternehmen engagiert sich für Gesundheit, Ernährung und nachhaltige Entwicklung."
      }
    },
    "linkedInTexts": [
      "Hallo [Name], ich würde mich freuen, mich mit Ihnen zu vernetzen, um neue Einblicke zu gewinnen. Gruß, [Dein Name]",
      "Hallo [Name], lassen Sie uns vernetzen, um über Chancen einer Zusammenarbeit zu sprechen. Gruß [Dein Name]",
      "Hallo [Name], ich lade Sie herzlich ein, sich mit mir zu vernetzen. Gruß [Dein Name]"
    ]
  },
  "en": {
    "header": { "results": "Results", "profiles": "Profiles" },
    "linkedIn": {
      "button": "Generate LinkedIn Connection Request",
      "text": {
        "header": "Text Suggestions for LinkedIn Connection",
        "openLink": "Open LinkedIn"
      }
    },
    "generatedTexts": {
      "titles": ["Value Proposition", "Cover Letter", "Compatibility"]
    },
    "profiles": {
      "baseCompany": {
        "name": "B2BScan",
        "description": "b2bScan is a revolutionary AI-powered solution specifically developed to assist companies in the B2B sector with new customer acquisition. By simply entering their own company website and target customer information, b2bScan delivers comprehensive information within just about 3 minutes, including the target customer's company profile, compatibility analysis, arguments for collaboration, and a customer-specific cover letter. Compared to conventional methods, which can take up to 3 hours, b2bScan offers significant time and cost savings. Users can create their own company scan profiles to check the basic compatibility with a target customer, as well as product scan profiles for targeted product offerings. Additionally, the platform allows for uploading a list of potential target customers for efficient acquisition and provides an archive area for accessing conducted scans and a flexible adjustment of the scan volume through credits. The USP of b2bScan lies in the use of AI to simplify and optimize customer acquisition and expansion without the need for special expertise. The platform enhances efficiency, reduces costs, and enables broad user accessibility within a company."
      },
      "targetCompany": {
        "name": "Bayer AG",
        "description": "Bayer is a global enterprise in the health and agriculture sectors. With the aim of improving human life, Bayer develops innovative products and solutions. The company is committed to health, nutrition, and sustainable development."
      }
    },
    "linkedInTexts": [
      "Hello [Name], I would be pleased to connect with you to gain new insights. Regards, [Your Name]",
      "Hello [Name], let's connect to discuss opportunities for collaboration. Regards, [Your Name]",
      "Hello [Name], I warmly invite you to connect with me. Regards, [Your Name]"
    ]
  }
}
</i18n>
