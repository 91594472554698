<template>
  <TheDialog :open="props.isOpen">
    <template #header>
      <TheDialogHeader
        :title="t('dialogTitle')"
        @click="$emit('close:dialog')"
      />
      <UiDialogDescription />
    </template>
    <template #content>
      <form
        class="flex flex-col items-center justify-center w-full h-full gap-6 p-2 pb-4 mt-4 md:p-6"
        @submit="onSubmit"
      >
        <template v-for="field in formFields" :key="field.name">
          <FormField
            v-slot="{ componentField }"
            :name="field.name"
            class="w-1/2"
          >
            <UiFormItem class="w-full md:w-2/3">
              <UiFormControl>
                <template v-if="field.name === 'message'">
                  <UiTextarea
                    :type="field.type"
                    :placeholder="t(`placeholders.${field.name}`)"
                    v-bind="componentField"
                    :class="field.class"
                  />
                </template>
                <template v-else>
                  <UiInput
                    :type="field.type"
                    :placeholder="t(`placeholders.${field.name}`)"
                    v-bind="componentField"
                    :class="field.class"
                  />
                </template>
              </UiFormControl>
              <UiFormMessage />
            </UiFormItem>
          </FormField>
        </template>
        <div class="flex justify-end w-full md:w-2/3">
          <UiButton class="h-12.5 iq-button iq-button-success">
            {{ t('sendButton') }}
          </UiButton>
        </div>
      </form>
    </template>
  </TheDialog>
</template>

<script setup lang="ts">
import { FormField } from '@/components/ui/form';

import { defineEmits, defineProps, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useReCaptcha } from 'vue-recaptcha-v3';
import { toast } from 'vue-sonner';
import * as z from 'zod';

const { t } = useI18n();
const props = defineProps<{ isOpen: boolean }>();
const emit = defineEmits(['close:dialog']);
const recaptcha = useReCaptcha();

const formFields = ref([
  { name: 'name', type: 'text', placeholder: 'name', class: '' },
  {
    name: 'company',
    type: 'text',
    placeholder: 'company',
    class: 'my-2 md:my-4 lg:my-0',
  },
  { name: 'email', type: 'email', placeholder: 'email', class: '!py-0' },
  {
    name: 'phone',
    type: 'phone',
    placeholder: 'phone',
    class: 'mt-2 md:mt-4 lg:mt-0',
  },
  {
    name: 'message',
    type: 'text',
    placeholder: 'message',
    class: '!min-h-[160px]',
  },
]);

const formSchema = toTypedSchema(
  z.object({
    email: z.string().email(t('validation.invalidEmail')),
    name: z.string().min(1, t('validation.enterName')),
    company: z.string().optional(),
    phone: z.string().optional(),
    message: z.string().optional(),
  })
);

const { handleSubmit, resetForm } = useForm({
  validationSchema: formSchema,
});

const onSubmit = handleSubmit(async (values) => {
  try {
    const token = await recaptcha?.executeRecaptcha('contact');
    await $fetch('/api/contact', {
      method: 'POST',
      body: { ...values, type: 'CONTACT' },
      headers: { 'X-Recaptcha-Token': token ?? '' },
    });
    resetForm();
    emit('close:dialog');
    toast.success(t('messages.messageSent'));
  } catch {
    toast.error(t('messages.errorOccurred'));
  }
});
</script>
<i18n lang="json">
{
  "en": {
    "dialogTitle": "Enterprise Subscription Inquiry",
    "placeholders": {
      "name": "Name *",
      "company": "Company",
      "email": "E-Mail *",
      "phone": "Phone",
      "message": "What's on your mind?"
    },
    "sendButton": "SEND",
    "validation": {
      "enterName": "Please enter a name.",
      "invalidEmail": "Invalid E-Mail address."
    },
    "messages": {
      "messageSent": "Your message has been sent. We will contact you shortly!",
      "errorOccurred": "An error occurred. Please try again later."
    }
  },
  "de": {
    "dialogTitle": "Enterprise Abonnement Anfrage",
    "placeholders": {
      "name": "Name *",
      "company": "Firma",
      "email": "E-Mail *",
      "phone": "Telefon",
      "message": "Was liegt Ihnen auf dem Herzen?"
    },
    "sendButton": "SENDEN",
    "validation": {
      "enterName": "Bitte Namen eingeben.",
      "invalidEmail": "Keine gültige E-Mail-Adresse."
    },
    "messages": {
      "messageSent": "Deine Nachricht wurde versendet. Wir melden uns in Kürze bei dir!",
      "errorOccurred": "Ein Fehler ist aufgetreten. Bitte versuche es später noch einmal."
    }
  }
}
</i18n>
