<template>
  <div id="pricing" class="relative landing-page-section !px-0 bg-iq-grey-dark">
    <a id="section-pricing" class="absolute top-0 -mt-20" />
    <div class="px-8 text-center">
      <h2
        :key="locale"
        v-highlight-index="locale === 'de' ? 3 : 2"
        class="mt-12 lg:self-center text-bold-64 lg:text-bold-108"
      >
        {{ t('title') }}
      </h2>
      <div class="mt-8 lg:text-regular-14 lg:text-center">
        {{ t('text') }}
      </div>
    </div>

    <div class="relative flex flex-col md:min-h-[600px] w-full mt-12">
      <ThePricingCards
        :is-shown-basic="true"
        :is-shown-pro="true"
        @click:cta="(priceId: string | undefined, tier: TierLevel) => onCtaClick(priceId, tier)"
      />
    </div>
    <ClientOnly>
      <TheContactFormularDialog
        :is-open="showContactFormular"
        @close:dialog="showContactFormular = false"
      />
    </ClientOnly>
  </div>
</template>
<script lang="ts" setup>
import type { TierLevel } from '~/enum/tier-level.enum';

const { t, locale } = useI18n();

const showContactFormular = ref(false);
async function onCtaClick(priceId: string | undefined, tier: TierLevel) {
  trackAtServer('purchase_landing_button', {
    tier,
  });

  if (!priceId) {
    showContactFormular.value = true;
    return;
  }

  await navigateTo({
    path: '/login',
    query: {
      priceId,
    },
  });
}
</script>
<i18n lang="json">
{
  "en": {
    "text": "We will try to answer all your important questions about B2BSCAN. If you have any questions, please get in touch with us directly.",
    "title": "PRICING"
  },
  "de": {
    "text": "Wir versuchen dir alle wichtigen Fragen rund um B2BSCAN zu beantworten. Solltest du eine Frage haben, setze dich gerne direkt mit uns in Verbindung.",
    "title": "PREISE"
  }
}
</i18n>
