<template>
  <div
    class="min-h-[100dvh] flex flex-col relative w-full overflow-scroll lg:overflow-hidden"
  >
    <TheLandingPageSectionHero
      @scan:start="onStartScan"
      @scan:compatibility="onStartCompatibility"
      @scan:salestext="onStartSalestext"
      @scan:letter="onStartLetter"
      @scan:letter:finish="onScanFinish"
      @scan:error="onError"
      @scan:has-scanned-user="onHasScannedUser"
    />

    <TheLandingPageSectionFeatures />
    <TheLandingPageSectionPricing />
    <TheLandingPageSectionAboutUs />
    <ClientOnly>
      <TheLandingPageSectionContact />
    </ClientOnly>
    <TheLoadingFooter
      v-if="isScanning"
      class="!fixed !pb-8 md:!pb-10"
      :loading-text="loadingText"
      :show-button="showButton"
      :button-text="t('buttonText.signup')"
      :is-on-landing-page="true"
      @click:footer:cta="onClickFooterCta"
    />
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n({ useScope: 'local' });

const isScanning = ref(false);
const loadingText = ref('');
const showButton = ref(false);

function onClickFooterCta() {
  return navigateTo({
    path: '/login',
    query: {
      showSignup: 'true',
    },
  });
}

function onStartScan() {
  isScanning.value = true;
  loadingText.value = t('loading.profileCreation');
}

function onStartCompatibility() {
  loadingText.value = t('loading.compatibilityCheck');
}

function onStartSalestext() {
  loadingText.value = t('loading.salesArgumentation');
}

function onStartLetter() {
  loadingText.value = t('loading.letterCreation');
}

function onScanFinish() {
  loadingText.value = '';
  showButton.value = true;
}

function onError() {
  loadingText.value = '';
  isScanning.value = false;
}

function onHasScannedUser() {
  showButton.value = true;
  isScanning.value = true;
}

onBeforeMount(async () => {
  if (useUserStore().user) {
    await navigateTo('/scan');
  }
});

useHead({
  title: t('pageTitle'),
});
</script>

<i18n lang="json">
{
  "en": {
    "loading": {
      "profileCreation": "1/4 - Creating profiles",
      "compatibilityCheck": "2/4 - Checking compatibility",
      "salesArgumentation": "3/4 - Creating sales argumentation",
      "letterCreation": "4/4 - Crafting letters"
    },
    "buttonText": {
      "signup": "SIGN UP FOR FREE"
    },
    "pageTitle": "B2BSCAN - AI-POWERED CUSTOMER ACQUISITION"
  },
  "de": {
    "loading": {
      "profileCreation": "1/4 - Profile werden erstellt",
      "compatibilityCheck": "2/4 - Kompatibilität wird geprüft",
      "salesArgumentation": "3/4 - Verkaufsargumentation wird erstellt",
      "letterCreation": "4/4 - Anschreiben wird erstellt"
    },
    "buttonText": {
      "signup": "JETZT KOSTENLOS ANMELDEN"
    },
    "pageTitle": "B2BSCAN - KI-GESTÜTZTE NEUKUNDEN-GEWINNUNG"
  }
}
</i18n>
