<template>
  <TheTabsContent
    :title="header"
    :image="imgTabsScan"
    img-height="150"
    img-width="150"
    :text="text"
    :img-alt="imgAlt"
    :img-title="imgTitle"
  />
</template>
<script lang="ts" setup>
import imgTabsScan from '/images/tabs-archiv.webp';
const { t } = useI18n({
  useScope: 'local',
});
const text = computed(() => t('archiveText'));
const imgAlt = computed(() => t('imgAlt'));
const imgTitle = computed(() => t('imgTitle'));
const header = computed(() => t('archive'));
</script>

<i18n lang="json">
{
  "en": {
    "archiveText": "Every target customer scan you perform is saved in the archive. This allows you to access them at any time and, for example, transfer them to a CRM. As B2BSCAN evolves, the target customer profiles stored in your archive will be used as an important data set for further AI functions of B2BSCAN.",
    "imgAlt": "A screenshot of the application's archive page",
    "imgTitle": "Archive Page",
    "archive": "Archive"
  },
  "de": {
    "archiveText": "Jeder von dir durchgeführte Zielkunden-Scan wird im Archiv gespeichert. So hast du jederzeit die Möglichkeit, diese wieder aufzurufen und zum Beispiel in ein CRM zu übernehmen. Im Zuge der Weiterentwicklung von B2BSCAN wirst du die, in deinem Archiv gespeicherten, Zielkundenprofile als wichtigen Datensatz für weitere KI-Funktionen von B2BSCAN nutzen können.",
    "imgAlt": "Eine Bildschirmaufnahme der Archivseite der Applikation",
    "imgTitle": "Archivseite",
    "archive": "Archiv"
  }
}
</i18n>
