<template>
  <div>
    <div class="relative">
      <div
        ref="tabsContainerRef"
        class="relative flex w-full overflow-x-auto o [scrollbar-width:none] tabs-container"
      >
        <button
          v-for="(tab, idx) in tabLabels"
          :id="`tab-${idx}`"
          :key="idx"
          :ref="setTabRef(idx)"
          class="!px-8 border-b tab-button flex-1 basis-auto py-2 border-b-white shrink-0"
          @click="setActiveTabIndex(idx)"
        >
          {{ tab }}
        </button>
      </div>
      <span
        class="absolute bottom-0 h-1 bg-white"
        :class="{ 'animate-transition': shouldAnimate }"
        :style="{
          left: `${tabUnderlineLeft}px`,
          width: `${tabUnderlineWidth}px`,
        }"
      />
    </div>
    <transition-group :name="`slide-${slideDirection}`">
      <component
        :is="getActiveTabComponent"
        :key="activeTabIndex"
        @touchstart="handleTouchStart"
        @touchend="handleTouchEnd"
      />
    </transition-group>
    <AppIndexIndicator
      class="mt-6 lg:hidden"
      :total-indices="5"
      :active-index="activeTabIndex + 1"
    />
  </div>
</template>

<script lang="ts" setup>
import { computed, onMounted, onUnmounted, ref, watch } from 'vue';

const props = defineProps({
  tabLabels: {
    type: Array,
    default: () => [],
  },
  tabContents: {
    type: Array,
    default: () => [],
  },
});

const activeTabIndex = ref(0);
const shouldAnimate = ref(true);
const slideDirection = ref('left');
const tabUnderlineWidth = ref(0);
const tabUnderlineLeft = ref(0);
const tabsContainerRef = ref<HTMLDivElement | null>(null);
const tabRefs = ref<Array<HTMLElement | undefined>>(
  props.tabLabels!.map(() => undefined)
);
const startX = ref(0);
const endX = ref(0);

const setTabRef = (idx: number) => (el: unknown) => {
  tabRefs.value[idx] = el as HTMLElement;
};

const getActiveTabComponent = computed(() => {
  return props.tabContents?.[activeTabIndex.value];
});

function scrollTabIntoView(tab?: HTMLElement) {
  if (tab) {
    tab.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
      inline: 'center',
    });
  }
}

const setActiveTabIndex = (idx: number) => {
  slideDirection.value = activeTabIndex.value < idx ? 'left' : 'right';
  if (activeTabIndex.value !== idx) {
    shouldAnimate.value = true;
    const tab = tabRefs.value[idx];
    activeTabIndex.value = idx;
    scrollTabIntoView(tab);
  }
};

const handleTouchStart = (event: TouchEvent) => {
  startX.value = event.touches[0].clientX;
};

const handleTouchEnd = (event: TouchEvent) => {
  endX.value = event.changedTouches[0].clientX;
  const difference = startX.value - endX.value;
  if (Math.abs(difference) > 20) {
    if (difference > 0 && activeTabIndex.value < props.tabLabels!.length - 1) {
      const index = activeTabIndex.value + 1;
      setActiveTabIndex(index);
    } else if (difference < 0 && activeTabIndex.value > 0) {
      const index = activeTabIndex.value - 1;
      setActiveTabIndex(index);
    }

    setTimeout(() => {
      setTabPosition();
      const tab = tabRefs.value[activeTabIndex.value];
      scrollTabIntoView(tab);
    }, 500);
  }
};

function setTabPosition() {
  const currentTab = tabRefs.value[activeTabIndex.value];
  if (currentTab && tabsContainerRef.value) {
    const containerScroll = tabsContainerRef.value.scrollLeft;
    tabUnderlineLeft.value = currentTab.offsetLeft - containerScroll;
    tabUnderlineWidth.value = currentTab.clientWidth;
    setTimeout(() => {
      shouldAnimate.value = false;
    }, 200);
  }
}

watch(activeTabIndex, setTabPosition);

onMounted(() => {
  setTabPosition();
  window.addEventListener('resize', setTabPosition);
  tabsContainerRef.value?.addEventListener('scroll', setTabPosition);
});

onUnmounted(() => {
  window.removeEventListener('resize', setTabPosition);
  tabsContainerRef.value?.removeEventListener('scroll', setTabPosition);
});
</script>

<style scoped>
.tabs-container {
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none;
}

.tabs-container::-webkit-scrollbar {
  display: none;
}

.animate-transition {
  transition: all 0.3s ease-out;
}

.tab-content > * {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
}

.slide-left-enter-active,
.slide-left-leave-active,
.slide-right-enter-active,
.slide-right-leave-active {
  transition: all 0.3s ease-in-out;
}

.slide-left-leave-active,
.slide-right-leave-active {
  position: absolute !important;
}

.slide-left-leave-to,
.slide-right-enter-from {
  transform: translateX(-100%);
  opacity: 0;
}

.slide-right-leave-to,
.slide-left-enter-from {
  transform: translateX(100%);
  opacity: 0;
}
</style>
